/* eslint no-underscore-dangle: ["off", { "allowAfterThis": true }] */

import React, { useState } from 'react';
import Hero from '../../components/Hero/Hero';
import SEO from '../../components/SEO/SEO';
import Micro from '../../layouts/Micro';

// Import Components
import RefinementList from '../../components/Discovery/RefinementList/RefinementList';
import SearchBox from '../../components/Discovery/SearchBox/SearchBox';
import SearchResults from '../../components/Discovery/SearchResult/SearchResult';

// Import Data
import data from '../../../data/legal-partners.json';

const LegalPartners = ({ location }) => {
  const cardData = data;
  const [isClicked, setisClicked] = useState(false);
  const [activeItem, setActiveItem] = useState();
  const [query, setQuery] = useState();

  // Set Incoming Countries - !!!! make it better
  const countries = [];
  cardData.forEach((record) => {
    record._rawJson.fields.CountryLabel.forEach((country) => {
      const obj = {};
      obj.title = country;
      obj.slug = country;
      countries.push(obj);
    });
  });

  // Set an array of objects for the unique countries
  const uniqueCountries = [...new Set(countries.map((item) => JSON.stringify(item)))]
    .map((string) => JSON.parse(string))
    .sort((a, b) => a.title.localeCompare(b.title));

  // Results
  const [hits, setHits] = useState(cardData);

  // Update input func
  const updateInput = (input) => {
    if (input) {
      const filtered = cardData.filter((record) =>
        record['_rawJson']?.fields?.Name?.toLowerCase().includes(input.toLowerCase())
      );
      setQuery(input);
      setHits(filtered);
    }
  };

  const onSelect = (selectedItem) => {
    // const filtered = cardData.filter((hit) =>
    //   hit.country.forEach((country) =>
    //     country.toLowerCase().includes(selectedItem.toLowerCase())
    //   )
    // );
    const filtered = [];
    cardData.forEach((record) => {
      record._rawJson.fields.CountryLabel.forEach((country) => {
        if (
          country &&
          selectedItem &&
          typeof selectedItem === 'string' &&
          country.toLowerCase() === selectedItem.toLowerCase()
        ) {
          filtered.push(record);
        }
      });
    });

    if (!isClicked) {
      setisClicked(true);
      setActiveItem(selectedItem);
      setHits(filtered);
    } else {
      setisClicked(false);
      setActiveItem('');
      setHits(cardData);
    }
  };

  return (
    <>
      <SEO title="Meet our law firm partners" />
      <Hero title="Meet our law firm partners" location={location} isNeedMainLabel={false} />

      <section style={{ backgroundColor: '#fafafa', marginTop: '-5rem' }}>
        <Micro>
          <div className="container my-5">
            <div className="row">
              <div className="col-2">
                <RefinementList
                  menuTitle="Countries"
                  data={uniqueCountries}
                  onSelect={onSelect}
                  activeItem={activeItem}
                  isHorizontal={false}
                  isVertical
                />
              </div>
              <div className="col-4">
                <SearchBox
                  placeholder="Search a company name..."
                  query={query}
                  onChange={updateInput}
                />
                <SearchResults hits={hits} cardStyle="partners" />
              </div>
            </div>
          </div>
        </Micro>
      </section>
    </>
  );
};

export default LegalPartners;
