import React from 'react';
import { ArrowUpRight } from 'akar-icons';

import Link from '../Link/Link';

// Style
import './custom-card.scss';

const CustomCard = ({ info }) => {
  const { logo, name, slug, website, email, countries } = info;

  return (
    <div className="custom-card-container">
      <Link href={slug} title={name} target="_blank">
        <div className="custom-card-image">
          <figure>
            <img src={logo} alt={name} />
          </figure>
        </div>
      </Link>
      <div className="custom-card-text-area">
        <Link className="custom-card-title" href={slug} title={name}>
          {name}
        </Link>
        <Link className="custom-card-website-url" target="_blank" href={website} title={website}>
          Website <ArrowUpRight size="12" />
        </Link>
        <Link className="custom-card-email" target="_blank" href={`mailto:${email}`} title={email}>
          {email}
        </Link>
      </div>
      <div className="custom-card-icon-group">
        {countries &&
          countries
            .filter((item) => typeof item === 'string')
            .map((countryCode) => {
              const flag = `https://www.remoteteam.com/global/vendor/flag-icon-css/flags/4x3/${countryCode.toLowerCase()}.svg`;
              return <img key={countryCode} src={flag} alt={countryCode} />;
            })}
      </div>
    </div>
  );
};

export default CustomCard;
