import React from 'react';
import PropTypes from 'prop-types';
import CustomCard from '../../CustomCard/CustomCard';

const SearchResults = ({ hits = [] }) => (
  <div className="row mt-1 custom-card-grid-container">
    {hits.map((hit) => {
      const partnerData = {
        logo: hit.fields.Logo[0].thumbnails.full.url,
        name: hit.fields.Name,
        slug: hit.fields.Slug,
        website: hit.fields.Website,
        email: hit.fields.Email,
        countries: hit.fields.CountryCode,
      };
      return <CustomCard key={partnerData.name} info={partnerData} />;
    })}
  </div>
);

SearchResults.propTypes = {
  hits: PropTypes.array.isRequired, // eslint-disable-line
};

export default SearchResults;
