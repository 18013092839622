import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './search-box.scss';

import { Search } from 'akar-icons';

const SearchBox = ({ placeholder, query, onChange }) => (
  <form className="search-form-area">
    <div className="input-container">
      <Search
        strokeWidth={4}
        size={15}
        color="#2d46c1"
        style={{
          margin: '1rem',
        }}
      />

      <input
        className="search-input"
        type="text"
        placeholder={placeholder}
        aria-label={placeholder}
        onChange={(e) => onChange(e.target.value)}
        value={query}
      />
    </div>
  </form>
);

SearchBox.defaultProps = {
  query: '',
};

SearchBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  query: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SearchBox;
