/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
// Style
import './refinement-list.scss';

// eslint-disable-next-line consistent-return
const RefinementList = ({ menuTitle, data, onSelect, activeItem, isHorizontal, isVertical }) => {
  const [item, setItem] = useState();

  if (isHorizontal) {
    return (
      <div className="categories-container flex flex-start">
        <h4 className="categories-tab-title">
          {menuTitle}
          <span className="text-normal text-muted pl-1">|</span>
        </h4>
        <ul className="categories-tab flex flex-middle">
          {data.map((category) => (
            <li key={category.slug} className="categories-tab-item">
              <button
                type="button"
                href="#"
                className="categories-tab-item-link"
                onClick={() => {
                  setItem((prevState) => [prevState, category.slug]);
                  onSelect(item, category.slug);
                }}
                style={{
                  color: category.slug === activeItem ? '#2DC18C' : '#A7A9B2',
                }}
              >
                {category.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  if (isVertical) {
    return (
      <div className="categories-tab-vertical flex flex-column flex-start">
        <h4 className="categories-tab-vertical-title">{menuTitle}</h4>
        <ul className="categories-tab-vertical-list flex flex-column">
          {data.map((category) => (
            <li key={category.slug} className="categories-tab-vertical-list-item">
              <label
                aria-hidden="true"
                className="categories-tab-vertical-list-item-label flex flex-middle"
                onClick={() => {
                  setItem((prevState) => [prevState, category.slug]);
                  onSelect(item, category.slug);
                }}
              >
                <input
                  type="checkbox"
                  className="categories-tab-vertical-list-item-label-checkbox"
                  value={category.title}
                  defaultChecked={category.slug === activeItem}
                />
                <span className="categories-tab-vertical-list-item-label-checkbox-checkmark" />
                <span className="categories-tab-vertical-list-item-label-checkbox-label">
                  {category.title}
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};

export default RefinementList;
